import PropTypes from "prop-types"
import classNames from "classnames/bind"

// ---------------------------------------------------------

import ContentBlock from "@components/content"
import ErrorBoundary from "@components/error-boundary"
import Image from "@components/image"

// ---------------------------------------------------------

import {
  content,
  content_blocks,
  feature_image,
  grid_content,
  image_right,
  is_uneven,
  one_col,
  two_col,
  logo_container
} from "./styles.module.scss"

// ---------------------------------------------------------

const NestedColumns = (props) => {
  const { children, headerText, image, imageOnLeft, isUneven, logo } = props

  const contentClasses = classNames(grid_content, {
    [`${is_uneven}`]: isUneven,
    [`${image_right}`]: !imageOnLeft
  })

  return (
    <ErrorBoundary>
      <div className={contentClasses}>
        <Image
          className={feature_image}
          {...image}
          alt={image.alt}
          sizes="(min-width: 1440px) 50vw, (min-width: 960px) 480px, 100vw"
          style={{ objectFit: "contain" }}
        />
        <div className={content}>
          {logo && (
            <div className={logo_container}>
              <Image
                src={logo.src}
                alt={logo.alt}
                height={logo.height}
                width={logo.width}
                imgixParams={{ w: 960 }}
              />
            </div>
          )}
          {headerText && <ContentBlock content={headerText}></ContentBlock>}
          <div className={classNames(content_blocks, children.length <= 1 ? one_col : two_col)}>
            {children}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}

NestedColumns.propTypes = {
  /**
   * Specifies anything between the opening and closing tag.
   */
  children: PropTypes.node,

  /**
   * Renders header of content within grid.
   */
  headerText: PropTypes.object,

  /**
   * Image opposite text; defaults to right unless otherwise specified by `imageOnLeft`.
   */
  image: PropTypes.shape({
    alt: PropTypes.string,
    height: PropTypes.number,
    src: PropTypes.string.isRequired,
    width: PropTypes.number
  }),

  /**
   * If true, image will be on the left instead of the right.
   */
  imageOnLeft: PropTypes.bool,

  /**
   * Specifies if image should take up 1/3 of the grid (instead of default 1/2).
   */
  isUneven: PropTypes.bool,

  /**
   * Specifies a logo that dispalys above the header.
   */
  logo: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number
  })
}

export default NestedColumns
