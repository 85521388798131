import ReactSlider from "react-slider"
import { numberFormatter, getUrlAndType } from "@lib/utils"

// ---------------------------------------------------------
import React, { useState } from "react"
import Link from "next/link"
import PropTypes from "prop-types"
import classNames from "classnames"

// ---------------------------------------------------------

import fixtures from "./fixtures"

// ---------------------------------------------------------

import {
  amperity_result_container,
  amperity_result,
  tab_selector,
  tab,
  active_tab,
  calculator,
  user_input_group,
  calc_left,
  calc_right,
  benchmark_container,
  footnotes,
  gray_text,
  result,
  results_panel,
  step_number,
  horizontal_slider,
  input_field,
  thumb,
  track
} from "./styles.module.scss"

// Global utility functions
// ---------------------------------------------------------
const formatField = (value, format) => {
  if (format === "currency") {
    return `$ ${numberFormatter(value, 9)}`
  } else if (format === "%") {
    return `${numberFormatter(value, 9)}%`
  } else if (format === "x") {
    return `${numberFormatter(value, 9)}x`
  } else {
    return numberFormatter(value, 9)
  }
}

// The component
// ---------------------------------------------------------
const Calculator = ({ children, backgroundColor }) => {
  const [interacted, setInteracted] = useState(false)

  // set variables ---------------------------------------------------------
  const [selectedTab, setSelectedTab] = useState("ROAS")
  const roasDefaults = {
    mediaSpend: 5000000,
    startingRoas: 3,
    inputs: [
      {
        name: "mediaSpend",
        header: "Monthly media spend",
        average: true,
        description: "Industry benchmark: 7% of avg. monthly revenue<sup>[1]</sup>",
        inputFormat: "currency",
        min: 100000,
        max: 10000000
      },
      {
        name: "currentRoas",
        header: "Current Return on Ad Spend",
        average: true,
        description: " ",
        inputFormat: "x",
        min: 1,
        max: 5.9,
        step: 0.5,
        benchmark: 3,
        footnote: 2
      }
    ],
    resultsHeader: "Amperity impact on total monthly ROAS",
    resultsSubheader: "On average, Amperity customers see a 2x improvement in their ROAS"
  }

  const matchRateDefaults = {
    customers: 10000000,
    matchRate: 40,
    inputs: [
      {
        name: "customers",
        header: "Customers sent to paid channels",
        average: true,
        description: `May include "known prospects" that haven't yet transacted`,
        min: 50000,
        max: 15000000,
        step: 1000
      },
      {
        name: "matchRate",
        header: "Current media match rate",
        average: true,
        description: " ",
        inputFormat: "%",
        min: 1,
        max: 100,
        step: 1,
        benchmark: 40,
        footnote: 3
      }
    ],
    resultsHeader: "Amperity impact on total matched customers",
    resultsSubheader: "On average, Amperity customers see a 30% improvement in match rates"
  }

  const [roasCalc, setRoasCalc] = useState({
    mediaSpend: roasDefaults.mediaSpend,
    currentRoas: roasDefaults.startingRoas,
    currentResult: roasDefaults.mediaSpend * roasDefaults.startingRoas,
    amperityResult: roasDefaults.mediaSpend * roasDefaults.startingRoas * 2
  })

  const [matchRateCalc, setMatchRateCalc] = useState({
    customers: matchRateDefaults.customers,
    matchRate: matchRateDefaults.matchRate,
    currentResult: (matchRateDefaults.customers * matchRateDefaults.matchRate) / 100,
    amperityResult: ((matchRateDefaults.customers * matchRateDefaults.matchRate) / 100) * 1.3
  })

  // Functions & consts that do the math ---------------------------------------------------------
  // Util functions
  const updateInputValues = (value, type) => {
    // send event to GTM and Matomo
    if (typeof window !== "undefined" && !interacted) {
      window.dataLayer.push({ event: "calculator_interacted" })
      if (window._paq) {
        window._paq.push(["trackEvent", "Calculator", `Interacted`, `A4PM Calculator`])
      }
      setInteracted(true)
    }
    if (type === "currentRoas" || type === "mediaSpend") {
      const mediaSpend = type === "currentRoas" ? roasCalc.mediaSpend : value
      const currentRoas = type === "currentRoas" ? value : roasCalc.currentRoas
      const currentResult = mediaSpend * currentRoas
      const amperityResult = currentResult * 2

      setRoasCalc({
        ...roasCalc,
        [type]: value,
        currentResult,
        amperityResult
      })
    } else if (type === "customers" || type === "matchRate") {
      const customers = type === "customers" ? value : matchRateCalc.customers
      const matchRate = type === "customers" ? matchRateCalc.matchRate : value
      const currentResult = customers * (matchRate / 100)
      const amperityResult = currentResult * 1.3
      setMatchRateCalc({
        ...matchRateCalc,
        [type]: value,
        currentResult,
        amperityResult
      })
    }
  }

  const handleInputFieldChange = (userInputValue, type, max) => {
    // Remove initial spaces, commas and $ signs
    const computedValue = Number(String(userInputValue).replace(/\$|\s|,|%/g, ""))
    if (!isNaN(computedValue)) {
      // normalize value by ensuring input doesn't go beyond the max
      const normalizedValue = computedValue > max ? max : computedValue
      updateInputValues(normalizedValue, type)
    }
  }

  const clickTab = (tab_name) => {
    setSelectedTab(tab_name)
  }

  // Render the calculator ---------------------------------------------------------
  const calc = selectedTab === "ROAS" ? roasDefaults : matchRateDefaults
  const currentValues = selectedTab === "ROAS" ? roasCalc : matchRateCalc

  return (
    <section
      className={classNames({ [`background_${backgroundColor}`]: backgroundColor })}
      id="calculator"
    >
      {children}
      <TabSelector tabs={["ROAS", "Match Rate"]} selected={selectedTab} clickHandler={clickTab} />
      <div className={calculator}>
        <div className={calc_left}>
          {calc.inputs.map((input, index) => {
            return (
              <Inputs
                key={index}
                stepNumber={index + 1}
                {...input}
                inputValue={currentValues[input.name]}
                inputName={input.name}
                updateInputValues={updateInputValues}
                handleInputFieldChange={handleInputFieldChange}
              />
            )
          })}
        </div>
        <div className={calc_right}>
          {selectedTab === "ROAS" ? (
            <ResultsPanel {...roasCalc} {...roasDefaults} selectedTab={selectedTab} />
          ) : (
            <ResultsPanel {...matchRateCalc} {...matchRateDefaults} selectedTab={selectedTab} />
          )}
        </div>
      </div>
      <Footnotes fixtures={fixtures} />
    </section>
  )
}

export default Calculator

// define modules ---------------------------------------------------------

const TabSelector = ({ tabs, selected, clickHandler }) => {
  return (
    <div className={tab_selector}>
      {tabs.map((tab_name) => {
        return (
          <button
            className={classNames(tab, tab_name === selected ? active_tab : null)}
            key={tab_name}
            onClick={() => {
              clickHandler(tab_name)
            }}
          >
            <h5>{tab_name === "ROAS" ? "Return on Ad Spend" : tab_name}</h5>
          </button>
        )
      })}
    </div>
  )
}

const ResultsPanel = ({
  resultsHeader,
  resultsSubheader,
  currentResult,
  amperityResult,
  selectedTab
}) => {
  const formattedCurrentResult = numberFormatter(currentResult, 6)
  const formattedAmperityResult = numberFormatter(amperityResult, 6)
  const prefix = selectedTab === "ROAS" ? "$ " : null

  return (
    <div className={results_panel}>
      <h4>{resultsHeader}</h4>
      <p>{resultsSubheader}</p>
      <h6>Current {selectedTab === "Match Rate" ? "Monthly matched customers" : selectedTab}</h6>
      <span className={result}>
        {prefix}
        {formattedCurrentResult}
      </span>
      <h6>With Amperity {`(+${selectedTab === "ROAS" ? "100" : "30"}%)`}</h6>
      <div className={amperity_result_container}>
        <div className={amperity_result}>
          {prefix}
          {formattedAmperityResult}
        </div>
      </div>
    </div>
  )
}

const Inputs = ({
  stepNumber,
  benchmark,
  header,
  average,
  description,
  inputFormat,
  footnote,
  name,
  inputValue,
  min,
  max,
  step,
  updateInputValues,
  handleInputFieldChange
}) => {
  const position = `${((benchmark - min) / (max - min)) * 100}%`

  return (
    <div className={user_input_group}>
      <span className={step_number}>{stepNumber}</span>
      <h4>
        {header} {average ? <span className={gray_text}>(avg.)</span> : null}
      </h4>
      {description ? (
        <p className={gray_text} dangerouslySetInnerHTML={{ __html: description }} />
      ) : (
        <p>&nbsp;</p>
      )}
      <input
        className={classNames(input_field)}
        type="text"
        value={formatField(inputValue, inputFormat)}
        onChange={(e) => handleInputFieldChange(e.target.value, name, max)}
        maxLength={16}
      />
      <ReactSlider
        trackClassName={track}
        className={classNames(horizontal_slider)}
        thumbClassName={thumb}
        onChange={(value) => updateInputValues(value, name)}
        onAfterChange={(value) => updateInputValues(value, name)}
        min={min || 0}
        max={max || 100}
        step={step || 1}
        value={inputValue}
      />
      {benchmark ? (
        <div className={benchmark_container} style={{ left: position }}>
          <p className={gray_text}>
            Industry benchmark: {formatField(benchmark, inputFormat)}
            {footnote ? <sup>[{footnote}]</sup> : null}
          </p>
        </div>
      ) : null}
    </div>
  )
}

const Footnotes = ({ fixtures }) => {
  const Note = (items) => {
    return (
      <>
        {items.items.map((item, idx) => {
          const { url: convertedUrl, isExternal } = getUrlAndType(item.url)

          return (
            <span key={idx}>
              {items.items.indexOf(item) === 0 ? null : ", "}
              <Link
                href={convertedUrl}
                target={isExternal ? "_blank" : "self"}
                rel={isExternal ? "noreferrer" : "none"}
                key={convertedUrl}
              >
                {item.name}
              </Link>
            </span>
          )
        })}
      </>
    )
  }

  return (
    <div className={footnotes}>
      <p>
        <strong>References</strong>[1]: <Note items={fixtures.one} /> [2]:{" "}
        <Note items={fixtures.two} /> [3]: <Note items={fixtures.three} />
      </p>
    </div>
  )
}

TabSelector.propTypes = {
  /**
   * Tabs that select the calculator
   */
  tabs: PropTypes.array,

  /**
   * Selected Tab
   */
  selected: PropTypes.string,

  /**
   * Click handler
   */
  clickHandler: PropTypes.func
}

ResultsPanel.propTypes = {
  /**
   * Header in the results panel
   */
  resultsHeader: PropTypes.string,
  /**
   * Selection for the the calculator tab
   */
  selectedTab: PropTypes.oneOf(["ROAS", "Match Rate"]),
  /**
   * Header in the results panel
   */
  resultsSubheader: PropTypes.string,
  /**
   * Current result field
   */
  currentResult: PropTypes.number,

  /**
   * Ameprity result field
   */
  amperityResult: PropTypes.number,

  /**
   * Improvement %
   */
  improvement: PropTypes.number
}

Calculator.propTypes = {
  /**
   * Modules that appear before the calculator
   */
  children: PropTypes.node,

  /**
   * Background color
   */
  backgroundColor: PropTypes.oneOf([
    "white",
    "cement",
    "anvil",
    "ice",
    "lagoon",
    "limoncello",
    "off-black",
    "transparent",
    "dusk-gradient"
  ])
}

Inputs.propTypes = {
  /**
   * Label for the step number when using the calculator
   */
  stepNumber: PropTypes.number,
  /**
   * Input area header
   */
  header: PropTypes.string,
  /**
   * is this an average? Appends a gray "(avg.)" after the header inline
   */
  average: PropTypes.bool,
  /**
   * Descriptor
   */
  description: PropTypes.string,
  /**
   * Input used to target the numbers
   */
  name: PropTypes.string,
  /**
   * Flag for the format the number should be shown in
   */
  inputFormat: PropTypes.string,
  /**
   * function to update the state
   */
  updateInputValues: PropTypes.func,
  /**
   * Input handler functino
   */
  handleInputFieldChange: PropTypes.func,
  /**
   * min bound of the slider
   */
  min: PropTypes.number,
  /**
   * max bound of the slider
   */
  max: PropTypes.number,
  /**
   * Size of each step of the slider
   */
  step: PropTypes.number,
  /**
   * populates industry benchmark if available
   */
  benchmark: PropTypes.number,
  /**
   * Add footnote to benchmark if available
   */
  footnote: PropTypes.number,
  /**
   * Controls the input value
   */
  inputValue: PropTypes.number
}

Footnotes.propTypes = {
  /**
   * The array of items that populate the footnotes, it should be an object
   */
  fixtures: PropTypes.object
}
