import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

// ---------------------------------------------------------

import ErrorBoundary from "@components/error-boundary"

// ---------------------------------------------------------

import { calendly_container } from "./styles.module.scss"

// ---------------------------------------------------------

const Calendly = ({ url }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false)

  const classes = classNames("calendly-inline-widget", calendly_container)

  useEffect(() => {
    if (!scriptLoaded) {
      const calendlyScript = document.createElement("script")
      calendlyScript.src = "https://assets.calendly.com/assets/external/widget.js"
      calendlyScript.async = true

      calendlyScript.addEventListener("load", () => {
        setScriptLoaded(true)
      })

      document.body.appendChild(calendlyScript)

      return () => {
        document.body.removeChild(calendlyScript)
      }
    }
  }, [scriptLoaded])

  return (
    <ErrorBoundary>
      <div className={classes} data-url={url} />
    </ErrorBoundary>
  )
}

export default Calendly

Calendly.propTypes = {
  /**
   * Link to a calendly account.
   */
  url: PropTypes.string
}
