import { useRouter } from "next/router"
import PropTypes from "prop-types"
import classNames from "classnames"

// ---------------------------------------------------------

import { dehyphenate } from "@lib/utils"

// ---------------------------------------------------------

import { tags_container, theme_light } from "./styles.module.scss"

// ---------------------------------------------------------

const themeOptions = {
  light: theme_light
}

const TagList = ({ className, tags, theme, path }) => {
  const router = useRouter()

  const classes = classNames(className, tags_container, {
    [themeOptions[theme]]: themeOptions[theme]
  })

  /**
   * Builds the query object that will be passed to <Link /> for each filter
   * @param {String} group - E.g. "category" or "topic"
   * @param {String} filter - Filter value, e.g. "Customer Data Platform"
   * @returns {Object} Object with new query values
   */
  const handleClick = (group, filter) => {
    router.push(
      {
        pathname: path,
        query: {
          [group]: filter
        }
      },
      false,
      { shallow: false, scroll: true }
    )
  }
  return (
    <div className={classes}>
      {tags.map((tag, i) => {
        let cleanTag = tag // A tag that doesn't include the "industries" prefix
        let assignedGroup = "tags" // Either "tags" or "industries", depending on the presence of the "industries" prefix

        if (tag.includes("Industry:")) {
          cleanTag = dehyphenate(tag).replace("Industry:", "").trim()
          assignedGroup = "industries"
        }

        if (path) {
          return (
            <a key={i}>
              <button key={i} onClick={() => handleClick(assignedGroup, cleanTag)}>
                <span>{cleanTag}</span>
              </button>
            </a>
          )
        }
        return <span key={i}>{cleanTag}</span>
      })}
    </div>
  )
}

TagList.propTypes = {
  /**
   * Specifies additional class name
   */
  className: PropTypes.string,
  /**
   * A list of tags, accepts strings
   */
  tags: PropTypes.arrayOf(PropTypes.string),

  /**
   * Theme of the label
   */
  theme: PropTypes.string,

  /**
   * The path where next/router should redirect, if appliable.
   */
  path: PropTypes.string
}

export default TagList
