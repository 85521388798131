// ---------------------------------------------------------
/* eslint-disable import/no-anonymous-default-export */
// ---------------------------------------------------------

import Alert from "../alert-bar"
import Accordion from "../accordion"
import AccordionItem from "../accordion/accordion-item"
import BulletList from "../bullet-list"
import Calendly from "../calendly"
import Calculator from "../calculator-paid-media"
import Card from "../card"
import CustomerCard from "../card-rounded/card-customer"
import CareersDetail from "../careers/job"
import CareersIndex from "../careers/jobs"
import Carousel from "../carousel"
import ContentBlock from "../content"
import NestedColumns from "../nested-columns"
import Form from "../marketoForm"
import FullWidthVideo from "../fullwidth-video"
import Grid from "../grid"
import ContentWithCarousel from "../content-carousel"
import Image from "@components/image"
import ImageComparison from "@components/image-comparison"
import LogoTrain from "../logo-train"
import Map from "../google-map"
import PersonCard from "../card-rounded/card-person"
import PingPong from "../pingpong"
import PlatformDiagram from "../platform"
import Quote from "../quote"
import Rating from "../rating"
import Statistic from "../statistic"
import Video from "../video"
import Timeline from "../timeline"

// ---------------------------------------------------------

export default {
  Alert: Alert,
  Accordion: Accordion,
  AccordionItem: AccordionItem,
  BulletList: BulletList,
  Calendly: Calendly,
  Calculator: Calculator,
  Card: Card,
  CustomerCard: CustomerCard,
  CareersDetail: CareersDetail,
  CareersIndex: CareersIndex,
  Carousel: Carousel,
  ContentBlock: ContentBlock,
  NestedColumns: NestedColumns,
  Form: Form,
  FullWidthVideo: FullWidthVideo,
  Grid: Grid,
  ContentWithCarousel: ContentWithCarousel,
  Image: Image,
  ImageComparison: ImageComparison,
  LogoTrain: LogoTrain,
  Map: Map,
  Person: PersonCard,
  PingPong: PingPong,
  PlatformDiagram: PlatformDiagram,
  Quote: Quote,
  Rating: Rating,
  Statistic: Statistic,
  Video: Video,
  Timeline: Timeline
}
