import React from "react"
import Component from "./component"
import fixtures from "./fixtures"
import transform from "./transformer"

const Calculator = (props) => <Component {...transform(props)} />

export default Calculator

export { Component as component, fixtures }
