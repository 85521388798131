export default {
  one: [
    {
      name: "Deloitte",
      url: "https://www2.deloitte.com/ro/en/pages/about-deloitte/articles/studiu-deloitte-companiile-din-majoritatea-sectoarelor-directioneaza-mai-mult-de-jumatate-din-bugetul-de-marketing-catre-activitati-din-mediul-digital.html"
    },
    {
      name: "Gartner",
      url: "https://www.gartner.com/en/newsroom/press-releases/gartner-survey-reveals-marketing-budgets-have-increased-to-9-5--"
    }
  ],
  two: [
    {
      name: "Adacado",
      url: "https://adacado.com/blog/what-is-roas/"
    }
  ],
  three: [
    {
      name: "AdExchanger",
      url: "https://www.adexchanger.com/content-studio/study-cookies-low-match-rates-cost-ad-tech-millions-moving-off-cookies-may-be-the-answer/"
    }
  ]
}
