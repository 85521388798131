import React from "react"
import { ReactSVG } from "react-svg"

// ---------------------------------------------------------

import { container, cloud, cloud_2, base_layer } from "./styles.module.scss"

// ---------------------------------------------------------

const Cloud = () => {
  return (
    <div className={container}>
      <div className={base_layer}>
        <ReactSVG
          src={`/svg/a_to_z/base-layer.svg`}
          title="Illustrated Z, with a label that says 'Zen = Customer Data Confidence'"
        />
      </div>
      <ReactSVG className={cloud} src={`/svg/a_to_z/cloud2.svg`} />
      <ReactSVG className={cloud_2} src={`/svg/a_to_z/cloud.svg`} />
    </div>
  )
}

export default Cloud
