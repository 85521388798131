import { parameterize } from "@lib/utils"

const transformed = (input) => {
  return {
    ...input,
    style: parameterize(input.style)
  }
}

export default transformed
