import React from "react"

import Image from "@components/image"

import { desktop_image, mobile_image } from "./styles.module.scss"

const Timeline = () => {
  return (
    <>
      <Image
        className={desktop_image}
        src="/img/timeline_desktop.jpg"
        alt="Timeline of Amperity's company milestones"
        width={0}
        height={0}
        sizes="100vw"
      />
      <Image
        className={mobile_image}
        src="/img/timeline_mobile.jpg"
        alt="Timeline of Amperity's company milestones"
        width={0}
        height={0}
        sizes="100vw"
      />
    </>
  )
}

export default Timeline
