export default {
  cards: [
    {
      title: "Create a unified view",
      description:
        "Identify and understand your customers with a holistic view of their brand interactions.",
      image: {
        src: "https://images.ctfassets.net/5vqt5w81x2im/5UQto89ZHSxJb4pQ43XND/4d18a0f49321b6a88a1721f5154839b0/Product_FlowDiagram_Unify_Mobile.png",
        alt: "Data flowing into an identity resolution cluster",
        width: 1000,
        height: 1151
      }
    },
    {
      title: "Usable for everyone",
      description:
        "Accelerate time to value by giving teams in Marketing, Analytics, IT and more access to the data they need.",
      image: {
        src: "https://images.ctfassets.net/5vqt5w81x2im/4q1eFdJHir3BuHxRL3Wd0H/8b493269c72a7affed04bc98a116f343/Product_FlowDiagram_Unlock_Mobile.png",
        alt: "Database with input from Marketing, IT, and Analytics",
        width: 1000,
        height: 1151
      }
    },
    {
      title: "Fuel your stack",
      description:
        "Trusted, reliable customer data source that connects to all your marketing and technology investments.",
      image: {
        src: "https://images.ctfassets.net/5vqt5w81x2im/1sDlEhoqRvTUZz3OD4cne0/89fb814cdd0daeff7c232559f9eb6d70/Product_FlowDiagram_Unleash_Mobile.png",
        alt: "Circle chart signaling increase in loyalty, CLV, and retention, and a decrease in acquisition cost.",
        width: 1000,
        height: 1151
      }
    }
  ]
}
