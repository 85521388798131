import PropTypes from "prop-types"
import Link from "next/link"
import { useRouter } from "next/router"

// ---------------------------------------------------------

import Image from "@components/image"
import TagList from "@components/tag-list"

// ---------------------------------------------------------

import {
  customer_card,
  card_image_description,
  card_text_description,
  card_main
} from "./styles.module.scss"

// ---------------------------------------------------------

const handleClick = (category, currentUrl, destinationUrl) => {
  window._paq
    ? window._paq.push([
        "trackEvent",
        category,
        `Current Page: ${currentUrl}`,
        `Destination: ${destinationUrl}`
      ])
    : null

  if (window.drift) window.drift.page()

  // eslint-disable-next-line
  dataLayer.push({
    event: category,
    current_url: currentUrl,
    destination_url: destinationUrl
  })
}

const CustomerCard = ({ title, image, description, imageDescription, link, tags, linkedPage }) => {
  const router = useRouter()

  // Resolve where the title, image, link and tags come from
  const cardTitle = title || linkedPage?.title
  const pageType = linkedPage?.__typename == "Page" ? linkedPage?.pagePath : "resources"
  const cardLink = link || `${pageType}/${linkedPage?.slug}`
  const cardTags = tags || linkedPage?.contentfulMetadata?.tags
  const sortedTags = cardTags?.map((tag) => tag.name || tag)
  const cardImage = image || linkedPage?.logo

  const getCardDescription = () => {
    const cardImageDescription = linkedPage?.imageDescription || imageDescription

    return cardImageDescription ? (
      <Image
        className={card_image_description}
        {...cardImageDescription}
        alt={cardImageDescription.alt}
        style={{ objectFit: "contain" }}
        width={350}
        height={390}
      />
    ) : description ? (
      <div className={card_text_description} data-testid="customer-card-description">
        <p>{linkedPage?.description || description}</p>
      </div>
    ) : null
  }

  return (
    <Link
      href={cardLink}
      className={customer_card}
      data-testid="customer-card"
      onKeyDown={() => handleClick("card_click", router.asPath, cardLink)}
      onClick={() => handleClick("card_click", router.asPath, cardLink)}
    >
      <div className={card_main}>
        <Image src={cardImage.src} alt={cardImage.alt} width={300} height={100} />
        <h4>{cardTitle}</h4>
        {sortedTags?.length > 0 && <TagList tags={sortedTags} />}
      </div>
      {getCardDescription()}
    </Link>
  )
}

CustomerCard.propTypes = {
  /**
   * A compelling description of the customer's success story.
   */
  description: PropTypes.string,

  /**
   * Main card image. Usually the logo of the customer.
   */
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  }),
  /**
   * An image describing the customer's success story.
   */
  imageDescription: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  }),

  /**
   * A link to the customer story or case study.
   */
  link: PropTypes.string,

  /**
   * A link to the a page or resource to populate the card.
   */
  linkedPage: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    pagePath: PropTypes.string,
    logo: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    }),
    imageDescription: PropTypes.object,
    description: PropTypes.string,
    contentfulMetadata: PropTypes.object,
    __typename: PropTypes.string
  }),

  /**
   * Array of tags including topic and industry.
   */
  tags: PropTypes.array,

  /**
   * Name of the customer.
   */
  title: PropTypes.string.isRequired
}

export default CustomerCard
