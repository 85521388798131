import PropTypes from "prop-types"
import classNames from "classnames"

import LazyLoad from "react-lazyload"
import CountUp from "react-countup"

// ---------------------------------------------------------

import Button from "@components/button"

// ---------------------------------------------------------

import {
  stat_container,
  stat_description,
  stat_value,
  theme_highlight_teal,
  theme_highlight_yellow
} from "./styles.module.scss"

// ---------------------------------------------------------

const Statistic = ({ value, description, theme, buttonLabel, buttonLink }) => {
  const themeOptions = {
    "highlight-yellow": theme_highlight_yellow,
    "highlight-teal": theme_highlight_teal
  }

  const buttonTheme = theme === "highlight-yellow" ? "arrow-dark" : "arrow-light"

  const classes = classNames({
    [themeOptions[theme]]: themeOptions[theme]
  })

  let showButton = null
  let numericalValue = null || value?.match(/[\d.]+/)[0]
  let prefix = value?.slice(0, value?.indexOf(numericalValue)) // Anything that comes before the numerical value
  let suffix = null || value?.match(/(\D*)$/)[0] // Anything that comes after the numerical value

  const hasNumber = () => {
    return /[\d]+/.test(value)
  }

  const hasDecimal = () => {
    return /[.]+/.test(value) ? 1 : 0
  }

  /**
   * If value containers a number, return the CountUp animation along with corresponding prefix and suffix.
   * Otherwise, simply return the value.
   */
  const getValue = hasNumber() ? (
    <LazyLoad
      once
      offset={0}
      height={300}
      placeholder={
        <span className={stat_value}>
          {prefix}
          {numericalValue}
          {suffix}
        </span>
      }
    >
      <span className={stat_value}>
        <CountUp
          decimals={hasDecimal()}
          duration={1}
          end={numericalValue}
          prefix={prefix}
          start={0}
          suffix={suffix}
        />
      </span>
    </LazyLoad>
  ) : (
    <span className={stat_value}>{value}</span>
  )

  if (buttonLabel && buttonLink) {
    showButton = (
      <Button theme={buttonTheme} title={buttonLabel} labelAttr={buttonLabel} url={buttonLink}>
        {buttonLabel}
      </Button>
    )
  }

  return (
    <div className={classes} data-testid="statistic">
      <div className={stat_container}>{getValue}</div>
      <div className={stat_description}>{description}</div>
      {showButton}
    </div>
  )
}

Statistic.propTypes = {
  /**
   * Main value of the statistic.
   */
  value: PropTypes.string.isRequired,

  /**
   * A string describing what the statistic is about.
   */
  description: PropTypes.string,

  /**
   * The formatting theme of the value.
   */
  theme: PropTypes.string,

  /**
   * Text rendered inside the button
   */
  buttonLabel: PropTypes.string,

  /**
   * Specifies where the button below the value links to
   */
  buttonLink: PropTypes.string
}

export default Statistic
