import React from "react"
import PropTypes from "prop-types"
import Cloud from "./cloud"
import LazyLoad from "react-lazyload"

const CssAnimation = (props) => {
  const Animations = { cloud: Cloud }
  const Animation = Animations[props.name]

  return (
    <LazyLoad offset={300}>
      <Animation />
    </LazyLoad>
  )
}

export default CssAnimation

CssAnimation.propTypes = {
  name: PropTypes.oneOf(["cloud"])
}
