import { parameterize } from "@lib/utils"

const transformed = (input) => {
  return {
    ...input,
    backgroundImage:
      input.backgroundImage?.src || input.backgroundImage?.url || input.backgroundImage,
    backgroundColor: parameterize(input.backgroundColor),
    styling: parameterize(input.styling)
  }
}

export default transformed
