import React from "react"
import Component from "./component"
import fixtures from "./fixtures"
import transform from "./transformer"

const PingPong = (props) => <Component {...transform(props)} />

export default PingPong

export { Component as component, fixtures, transform as transformer }
