import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import dynamic from "next/dynamic"
import { ReactSVG } from "react-svg"

// ---------------------------------------------------------

import { hasDarkBackground } from "@lib/utils"

import CssAnimation from "../../animations/css"
import Image from "@components/image"
import ContentBlock from "@components/content"
import ErrorBoundary from "@components/error-boundary"

// ---------------------------------------------------------

const Podcast = dynamic(() => import("@components/podcast"), { ssr: false })
const Video = dynamic(() => import("@components/video"), { ssr: false })

// ---------------------------------------------------------

import {
  ping_pong_container,
  ping_pong_content,
  ping_pong_logo_container,
  media_container,
  text_container,
  media_on_left,
  text_first_on_mobile,
  light,
  social_media,
  dark_icons
} from "./styles.module.scss"

// ---------------------------------------------------------

const PingPong = (props) => {
  const {
    anchorId,
    cssAnimation,
    backgroundColor,
    className,
    children,
    image,
    imageOnLeft,
    logo,
    logoTitle,
    text,
    video,
    podcast,
    showSocialMedia
  } = props

  const classes = classNames(ping_pong_container, className, {
    [`background_${backgroundColor}`]: backgroundColor
  })

  const darkBackground = backgroundColor && hasDarkBackground(backgroundColor)

  const getMedia = () => {
    if (video) {
      return (
        <Video
          title={video.title}
          videoUrl={video.videoUrl}
          videoFile={video.videoFile}
          loopAndAutoplay={video.loopAndAutoplay}
          roundedEdges={video.roundedEdges}
          thumbnail={video.thumbnail}
        />
      )
    } else if (podcast) {
      return <Podcast url={podcast.url} theme="pingpong" />
    } else if (cssAnimation) {
      return <CssAnimation name={cssAnimation} />
    } else {
      return (
        <Image
          {...image}
          alt={image.alt}
          sizes="(min-width: 1440px) 50vw, (min-width: 960px) 480px, 100vw"
          style={{ objectFit: "contain" }}
        />
      )
    }
  }

  return (
    <ErrorBoundary>
      <div className={classes} id={anchorId}>
        <div
          className={classNames(
            ping_pong_content,
            imageOnLeft ? media_on_left : null,
            podcast ? text_first_on_mobile : null
          )}
        >
          <div className={`${text_container} ${darkBackground ? light : null} `}>
            {logo && (
              <div className={ping_pong_logo_container}>
                <Image
                  src={logo.src}
                  alt={logo.alt}
                  height={logo.height}
                  width={logo.width}
                  imgixParams={{ w: 960 }}
                />

                {logoTitle && <h6>{logoTitle}</h6>}
              </div>
            )}
            {children}
            {text && <ContentBlock content={text}></ContentBlock>}
            {showSocialMedia && (
              <section className={classNames(social_media, !darkBackground && dark_icons)}>
                <a
                  href="https://www.linkedin.com/company/amperity/"
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="LinkedIn"
                >
                  <ReactSVG src={`/icons/logo-linkedin.svg`} />
                </a>
                <a
                  href="https://www.youtube.com/amperity"
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="YouTube"
                >
                  <ReactSVG src={`/icons/logo-youtube.svg`} />
                </a>
                <a
                  href="https://www.instagram.com/amperity/"
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="Instagram"
                >
                  <ReactSVG src={`/icons/logo-instagram.svg`} />
                </a>
                <a
                  href="https://www.facebook.com/goamperity"
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="Instagram"
                >
                  <ReactSVG src={`/icons/logo-facebook.svg`} />
                </a>
                <a
                  href="https://twitter.com/amperity"
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="Twitter"
                >
                  <ReactSVG src={`/icons/logo-x-twitter.svg`} />
                </a>
              </section>
            )}
          </div>
          <div className={media_container}>{getMedia()}</div>
        </div>
      </div>
    </ErrorBoundary>
  )
}

PingPong.propTypes = {
  /**
   * Specifies an id for the container div.
   */
  anchorId: PropTypes.string,

  /**
   * Specifies the background color.
   */
  backgroundColor: PropTypes.oneOf([
    "lagoon",
    "amp-yellow",
    "anvil",
    "cement",
    "ice",
    "off-black",
    "white"
  ]),

  /**
   * Specifies whether or not the social media component is rendered
   */
  showSocialMedia: PropTypes.bool,

  /**
   * Specifies additional class name
   */
  className: PropTypes.string,

  /**
   * Animation - takes precedence over video or images
   */
  cssAnimation: PropTypes.string,

  /**
   * Image opposite text; defaults to right unless otherwise specified by `imageOnLeft`.
   */
  image: PropTypes.shape({
    alt: PropTypes.string,
    height: PropTypes.number,
    src: PropTypes.string.isRequired,
    width: PropTypes.number
  }),

  /**
   * If true, image will be on the left instead of the right.
   */
  imageOnLeft: PropTypes.bool,

  /**
   * Specifies an image for the logo.
   */
  logo: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number
  }),

  /**
   * Specifies a title for the logo.
   */
  logoTitle: PropTypes.string,

  /**
   * Specifies the podcast that should display opposite the text (overrides image selection).
   */
  podcast: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string
  }),

  /**
   * Specifies the text coming from the CMS (similar to children - do not alter manually)
   */
  text: PropTypes.object,

  /**
   * Specifies the video that should display opposite the text (overrides image selection).
   */
  video: PropTypes.shape({
    roundedEdges: PropTypes.bool,
    videoUrl: PropTypes.string,
    title: PropTypes.string,
    videoFile: PropTypes.object,
    loopAndAutoplay: PropTypes.bool,
    thumbnail: PropTypes.shape({
      alt: PropTypes.string,
      height: PropTypes.number,
      src: PropTypes.string.isRequired,
      width: PropTypes.number
    })
  })
}

PingPong.defaultProps = {
  backgroundColor: "white",
  imageOnLeft: false
}

export default PingPong
