import { Fragment } from "react"

// ---------------------------------------------------------

const content = (
  <Fragment>
    <h2>Test Ping Pong</h2>
    <p>
      Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
      mollit anim id{" "}
      <a href="https://example.com" target="_blank">
        est laborum.
      </a>
    </p>
  </Fragment>
)

const contentWithLabel = (
  <Fragment>
    <h6>This is a label</h6>
    {content}
  </Fragment>
)

// ---------------------------------------------------------

export default {
  componentProps: {
    children: contentWithLabel,
    image: {
      alt: "Illustration of airplane wing against sunset",
      height: 700,
      src: "/storybook/images/plane-hero.jpg",
      width: 1080
    }
  },
  withImageOnLeft: {
    backgroundColor: "anvil",
    imageOnLeft: true
  },
  withLogoInContentArea: {
    children: content,
    imageOnLeft: true,
    logo: {
      alt: "Profile Accelerator For Adobe",
      src: "/storybook/images/PROFACC_logo_Small.png",
      width: 50,
      height: 50
    },
    logoTitle: "Profile Accelerator For Adobe"
  },
  withVideo: {
    backgroundColor: "off-black",
    imageOnLeft: true,
    video: {
      videoUrl: "IEhX07tkcUk",
      title: "This title gets passed to Marketo"
    }
  },
  withAnimation: {
    backgroundColor: "cement",
    cssAnimation: "cloud"
  }
}
